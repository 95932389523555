


.modal-back{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba( 0, 0, 0, .5) ;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top:0;
	left: 0;
	z-index: 100;
}



.modal-card {
	color:var( --light-text );
	/* width: 60vw; */
	max-width: 90vw;
	min-width: 60vw;
	padding: 23px;
	background-color:  #F4EFE6;

  max-height:80vh;

	/* max-width: 700px; */
	/* background-color: #fff; */
	/* width: 60%; */
	padding: 20px;
	text-align: center;
	border-radius: 10px;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.modal-card .text-input-wrap input {


	color:white;
}
