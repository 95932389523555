


.check-box-wrap{
  display: inline-block;
  padding-left: 32px;
  padding-top:2px;
  position: relative;
  cursor: pointer;
  user-select: none;
}



.check-box-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  pointer-events:none;
}



.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  /*
  height: 0;
  width:0;
  */
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius:3px;
  border: 1px solid grey;
}


.check-box:checked + .check-mark {
  background-color: #4c0c77;
  border-color:transparent;
  border: 1px solid grey;
}


.check-mark:after {
  content: "";
  position: absolute;
  display: none;
}


.check-box-wrap input:checked ~ .check-mark:after {
  display: block;
}


.check-box-wrap .check-mark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
