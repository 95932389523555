.small-loader {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  position: relative;
  animation: rotate-small .5s linear infinite;
  display:block;

  border: 2px solid grey;
  border-top: 2px solid transparent;

}



@keyframes rotate-small {
  0%   {transform: rotate(0deg) }
  100%   {transform: rotate(360deg) }
}

