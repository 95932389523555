@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap');

body {


    background-color:#F4EFE6;


    user-select: none;
    font-family: 'Ubuntu', sans-serif;
}



input{
    user-select: text;
  }


main{
  padding-top:100px;
}
